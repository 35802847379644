<template>
  <router-view/>
</template>
<script>
  
</script>
<style>
  .no-select {
      /* 鼠标不能选中文本 */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* end */
  }
  .box {
      width: 100vw;
      height: 100vh;
      background: #fff;
    }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
