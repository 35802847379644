<!--

        订单管理 

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;" @tab-change="btnPane" v-model="label">
            <!-- 业务员 -->
            <el-tab-pane label="待审核" name="1" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>待审核</div><el-badge v-if="orderStateCount.audit != 0" class="badge" :value="orderStateCount.audit" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>
            
            <el-tab-pane label="未付款" name="6" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>未付款</div><el-badge v-if="orderStateCount.noPay != 0" class="badge" :value="orderStateCount.noPay" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

            <el-tab-pane label="已驳回" name="0" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已驳回</div><el-badge v-if="orderStateCount.reject != 0" class="badge" :value="orderStateCount.reject" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

            <!-- <el-tab-pane label="待发货" name="2" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>待发货</div><el-badge v-if="orderStateCount.noShip != 0" class="badge" :value="orderStateCount.noShip" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane> -->
            <el-tab-pane label="已发货" name="3" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已发货</div><el-badge v-if="orderStateCount.ship != 0" class="badge" :value="orderStateCount.ship" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>
            <el-tab-pane label="已签收" name="4" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已签收</div><el-badge v-if="orderStateCount.signIn != 0" class="badge" :value="orderStateCount.signIn" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>
           
            <!-- 财务 -->
            <el-tab-pane label="待标记" name="4" v-if="department.includes('财务') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>待标记</div><el-badge v-if="orderStateCount.signIn != 0" class="badge" :value="orderStateCount.signIn" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

            <!-- 公共 -->
            <el-tab-pane label="已完成" name="5">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已完成</div>
                    </span>
                </template>
            </el-tab-pane>
            <!-- 订单号/运单号搜索 -->
            <div class="searchBox">
                <div class="block" style="margin-right: 10px;">
                    <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    @change="electDate"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <el-input v-model="input" style="width: 400px;" placeholder="搜索订单号/运单号" @clear="handleClear" clearable/>
                <el-button style="margin-left: 10px;" type="primary" @click="searchBtn" circle><el-icon><Search /></el-icon></el-button>
            </div>

            <div style="height: 20px;"></div>
            <!-- 订单号数据 -->
              <el-table height="600" :data="tableData" :border="true"  v-loading="tableLoading" stripe>
                    <el-table-column type="expand" style="padding: 0 10px;">
                        <template #default="props">
                            <div m="4">
                            <div style="margin: 10px; margin-top: 0;">
                                <el-table :data="props.row.orderInfo" style="width: 100%" :border="true">

                                    <el-table-column label="订单号" width="80">
                                        <template #default="scope">
                                            <el-tooltip class="box-item" effect="dark" :content="scope.row.orderId" placement="top">
                                                <el-tag @click="copyText(scope.row.orderId,'订单号')">Copy</el-tag>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="配送方式"  width="100" align="center">
                                        <template #default="scope">
                                            <el-tag v-if="(scope.row.dispatchingType == 1)">闪送</el-tag>
                                            <el-tag v-if="(scope.row.dispatchingType == 2)">快递</el-tag>
                                            <el-tag v-if="(scope.row.dispatchingType == 3)">自提</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="运单号" width="300" align="center">
                                        <template #default="scope">
                                            <div v-if="scope.row.waybillNumber">
                                                <el-tooltip class="box-item" effect="dark" :content="scope.row.waybillNumber" placement="top">
                                                    <div @click="copyText(scope.row.waybillNumber,'运单号')" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.waybillNumber}}</div>
                                                </el-tooltip>
                                            </div>
                                            <div v-else-if="label == 2">
                                                <el-input v-if="scope.row.dispatchingType != 3" v-model="waybillNumber" placeholder="输入运单号" clearable></el-input>
                                                <el-text v-else>无需填写</el-text>
                                            </div>
                                            <el-text v-else>无</el-text>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="配送信息" align="center">
                                        <template #default="scope">
                                            <el-tooltip class="box-item" effect="dark" :content="scope.row.site" placement="top" v-if="scope.row.dispatchingType != 3 && scope.row.site.length != 0">
                                                <div @click="copyText(scope.row.site,'配送信息')" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.site}}</div>
                                            </el-tooltip>
                                            <el-text v-else>无</el-text>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="财务标记"  width="120" align="center">
                                        <template #default="scope">
                                            <el-tag type="success" v-if="(scope.row.sign == 1)">已付款</el-tag>
                                            <el-tag type="danger" v-else-if="(scope.row.sign == 2)">未付款</el-tag>
                                            <el-tag type="info" v-else>未标记</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="支付方式"  width="120" align="center">
                                        <template #default="scope">
                                            <el-text v-if="(scope.row.pay == 0)">秒款</el-text>
                                            <el-text v-if="(scope.row.pay == 1)">闲鱼</el-text>
                                            <el-text v-if="(scope.row.pay == 2)">代卖</el-text>
                                            <el-text v-if="(scope.row.pay == 3)">其他</el-text>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- 订单列表 -->
                            <div style="margin: 10px;" v-for="(order, index) in props.row.shopOrder" :key="index">
                                <el-table :border="true" :data="order.list" :class="order.label == '全新' ? 'orderNew':'orderUsed'">
                                    <el-table-column label="标签" align="center" width="100">
                                        <template #default>
                                            <el-text style="color: #fff;">{{order.label}}</el-text>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="标题" prop="title" align="center" />
                                    <el-table-column label="产品编号" prop="serialNumber" align="center" />
                                    <el-table-column label="调货价" prop="vipPrice" align="center" width="100"/>
                                    <el-table-column label="数量" prop="shopNum" width="100" align="center" show-summary />
                                    
                                    <el-table-column label="图片"  width="120" align="center">
                                        <template #default="scope">
                                            <el-image
                                                style="width: 80px; height: 80px;"
                                                :src="scope.row.originFiles[0].url"
                                                :infinite="false"
                                                z-index="2000"
                                                :zoom-rate="1.2"
                                                :max-scale="7"
                                                :min-scale="0.2"
                                                :preview-src-list="scope.row.originFiles.map(item => item.url)"
                                                preview-teleported="true"
                                                fit="cover"
                                            >
                                            <!-- <el-button size="small" @click="byPass(scope.row.orderId,scope.row.comId,scope.row.orderList)">查看图片</el-button> -->
                                            </el-image>
                                            
                                        </template>
                                    </el-table-column>

                                </el-table>
                            </div>
                            <!-- 订单列表end -->
                            </div>
                        </template>
                    </el-table-column>
                
                <el-table-column label="用户ID" width="80">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="dark" :content="scope.row.comId.toString()" placement="top">
                            <el-tag @click="copyText(scope.row.comId ,'用户ID')">Copy</el-tag>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" prop="regTime" width="120" /> 
                <el-table-column label="总金额" prop="money" width="120" align="center"/>
                <!-- <el-table-column label="姓名" prop="beName" width="100" align="center"/>  -->
                <el-table-column label="企业名称" prop="comName" />
                <!-- <el-table-column label="业务员" prop="operationName" width="120" align="center"/> -->
                <el-table-column label="状态" width="120" align="center">
                        <template #default="scope">
                            <el-tag type="warning" v-if="(scope.row.state == 1)">待审核</el-tag>
                            <el-tag v-else-if="(scope.row.state == 2)">待发货</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 3)">已发货</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 4) && !department.includes('财务')">已签收</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 4) && department.includes('财务')">待标记</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 5)">已完成</el-tag>
                            <el-tag type="danger" v-else-if="(scope.row.state  == 6)">未付款</el-tag>
                            <el-tag type="danger" v-else>已驳回</el-tag>
                        </template>
                    </el-table-column>

                <el-table-column :label="label == 0 ? '原因':'操作'" width="120" align="center">
                    <template #default="scope">
                        <el-text v-if="(label == 2 || label == 3) && department.includes('财务')">无</el-text>
                        <el-dropdown v-else-if="label == 1 || label == 2 || label == 3 || label == 4 || label == 6">
                            <span class="el-dropdown-link"><el-button link type="primary" size="small">选项</el-button></span>
                            <template #dropdown>
                            <el-dropdown-menu>
                                <!-- 业务员 -->
                                <div v-if="department.includes('运营') || grade == 1 && label != 11">
                                    <div v-if="(scope.row.state == 1)">
                                        <el-dropdown-item><el-button link type="primary" size="small" @click="setOrderMoneyDialogBtn(scope.row)">修改金额</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="warning" size="small" @click="nonPayment(scope.row.orderId)">未付款</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="success" size="small" @click="byPass(scope.row.orderId,scope.row.comId,scope.row.orderList)">通过</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="danger" size="small" @click="byReject(scope.row.orderId,scope.row.comId,scope.row.orderList)">驳回</el-button></el-dropdown-item>
                                    </div>
                                    <div v-if="(scope.row.state == 2)">
                                        <el-dropdown-item><el-button link type="primary" size="small" @click="byDeliver(scope.row.orderId,scope.row.dispatchingType)">发货</el-button></el-dropdown-item>
                                    </div>
                                    <div v-if="(scope.row.state == 3)">
                                        <el-dropdown-item><el-button link type="primary" size="small" @click="byFinish(scope.row.orderId)">已签收</el-button></el-dropdown-item>
                                    </div>
                                    <div v-if="(scope.row.state == 6)">
                                        <el-dropdown-item><el-button link type="success" size="small" @click="byPass(scope.row.orderId,scope.row.comId,scope.row.orderList)">通过</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="danger" size="small" @click="byReject(scope.row.orderId,scope.row.comId,scope.row.orderList)">驳回</el-button></el-dropdown-item>
                                    </div>
                                    
                                </div>
                                <!-- 财务 -->
                                <div v-if="department.includes('财务') || grade == 1 && label == 4">
                                    <el-dropdown-item><el-button link type="success" size="small" @click="byReceipt(scope.row.orderId,1)">已收款</el-button></el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <el-button v-else-if="label == 0" link type="success" size="small" @click="byRemark(scope.row.remark)">查看</el-button>
                        <el-text v-else>无</el-text>
                    </template>
                    </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="margin-top: 10px; display: flex; justify-content: center;">
                <Pagination :total="total" :page-size="pageSize"  @changePage="changePage"/>
            </div>
        </el-tabs>

        <!-- 修改价格 -->
        <el-dialog title="修改订单金额" v-model="setOrderMoneyDialog" width="800">
            <el-table :data="shopOrder" width="700">
                <el-table-column property="title" label="名称" width="350"></el-table-column>
                <el-table-column property="vipPrice" label="价格" width="350">
                    <template #default="scope">
                        <el-input @input="setMoneyInput(scope.row.scope)" v-model="scope.row.vipPrice" type="number" placeholder="输入金额" style="width: 150px" clearable></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <div class="dialog-footer">
                    <el-text class="mx-1" size="large" type="info">合计: </el-text>
                    <el-text class="mx-1" type="danger">{{totalPrices}}</el-text>
                    <text style="margin-right: 50px"></text>
                    <el-button type="primary" @click="updateMoney">确认修改</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../components/PageinationView.vue';
import Host from "../store/host";
import Method from "../store/publicMethod";
import CryptoJS from 'crypto-js';
export default {
    components: {
        Pagination
    },
    data(){
        return {
            tableData: [],
            orderStateCount:{},
            label: "1",
            tableLoading: false,
            pageSize: 1,
            pageCount: 1,
            total: 0,
            input: "",
            grade: localStorage.getItem('grade'),
            department: localStorage.getItem('department'),
            waybillNumber: '', // 运单号
            dialogType: 1,
            dialogFormVisible: true,
            dateValue: "",
            // 修改价格
            setOrderMoneyDialog: false,
            shopOrder: [],
            orderId: null,
            totalPrices: 0,
            // end
        }
       
    },
    mounted() {
       
    },
    created(){
        this.getOrderStateCount();
        this.$nextTick(()=>{});
        if(this.department.includes('财务')){this.label = "4"} else {this.label = "1"}
        this.changePage(1);
    },
    methods: {
      // 分页
      changePage(num){
        this.pageCount = num;
        this.getList();
      },
      // 获取列表
      async getList(){
        this.tableLoading = true;
        let postUrl = `/javaApi/order/select?state=${this.label}&page=${this.pageCount}`;
        let resolveData = await Host.HostGet(postUrl);
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        if(!resolveData.data) return;
        let arr = await this.setOrderList(resolveData.data)
        this.tableData = await Host.setList(arr);
        console.log(this.tableData )
        this.total = resolveData.total
        this.tableLoading = false;

      },
      btnPane(e){
        this.label = e;
        this.dateValue = "";
        this.changePage(1);
      },
      // 修改金额弹窗
        setOrderMoneyDialogBtn(e){
            let arr = new Array();
            for(let i = 0; i < e.shopOrder.length; i++){
                for(let j = 0; j < e.shopOrder[i].list.length; j++){
                    let obj = new Object();
                    obj.id =  e.shopOrder[i].list[j].id;
                    obj.title =   e.shopOrder[i].label + '-' + e.shopOrder[i].list[j].title;
                    obj.vipPrice =  e.shopOrder[i].list[j].vipPrice;
                    arr.push(obj)
                }
            }
            this.shopOrder = arr;
            this.setMoneyInput();
            this.orderId = e.orderId;
            this.setOrderMoneyDialog = true;
        },
      // 金额输入框
        setMoneyInput(e){
          // 重新计算总计
          let totalPrices = 0;
          for(let i = 0; i < this.shopOrder.length; i++){
              totalPrices += parseFloat(this.shopOrder[i].vipPrice);
          }
          this.totalPrices = parseFloat(totalPrices)
        },
      // 修改订单金额
        updateMoney(){
            this.$alert('确认要修改吗?', '提示', {
                confirmButtonText: '确定',
                callback: async action => {
                    if(isNaN(this.totalPrices)) return this.$message({message: "内容不完整", type: 'warning'});

                    const key = CryptoJS.enc.Utf8.parse('9be748311cebd007');
                    const iv = CryptoJS.enc.Utf8.parse('54f14d8c004cbb4e');
                    // 执行加密操作
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(this.shopOrder), key,
                        {iv: iv, mode: CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7}).toString();

                    // 验证通过，请求修改
                    let postUrl = `/javaApi/order/setMoney?totalPrices=${this.totalPrices}`;
                    let data = {
                        orderId: this.orderId,
                        shopCart: encryptedData
                    }
                    let resolveData = await Host.HostPost(postUrl,data);
                    if (resolveData.code != 200) return this.$message({message: resolveData.msg, type: 'warning'});
                    await this.getList();
                    this.setOrderMoneyDialog = false;
                    this.shopOrder = [];
                    this.orderId = null;
                    this.totalPrices = 0;
                    this.$message({message: resolveData.msg, type: 'success'});
                }
            })
        },
      // 通过
      byPass(orderId,comId,orderList){
        console.log(orderId)
        let data = {
            orderId: orderId,
            comId:comId,
            orderList: orderList,
            state: 3, // 待发货2 已发货3
        }
        this.updateOrder(data);
      },
      // 未付款
      nonPayment(orderId){
        let data = {
            orderId: orderId,
            state: 6,
        }
        this.updateOrder(data);
      },
      // 驳回
      byReject(orderId,comId,orderList){
        this.$prompt('', '驳回', {
            confirmButtonText: '确认驳回',
            cancelButtonText: '取消',
            inputPlaceholder: '请输入驳回原因',
            inputPattern:  /^.{1,15}$/,
            inputErrorMessage: '文本长度不合法'
            }).then(async({ value }) => {
                let data = {
                    orderId: orderId,
                    comId: comId,
                    remark: value,
                    orderList: orderList,
                    state: 0,
                }
                this.updateOrder(data);
            }).catch(() => {
        });

      },
      // 发货
      byDeliver(orderId,dispatchingType){
        console.log(dispatchingType,this.waybillnumber)
        let data = null;
        if(dispatchingType != 3){
            if(this.waybillNumber.length != 0) {
                data = {
                    orderId: orderId,
                    waybillnumber: this.waybillNumber,
                    state: 3,
                }
            } else return this.$message({message: '请输入运单号',type: 'warning'});
        } else {data = {orderId: orderId,state: 3}}
        console.log(data)
        this.updateOrder(data);
        this.waybillNumber = "";
      },
      // 完成
      byFinish(orderId){
        let data = {
            orderId: orderId,
            state: 4,
        }
        this.updateOrder(data);
      },
     // 财务标记
     byReceipt(orderId,sign){
        let data = {
            orderId: orderId,
            sign:sign,
            state: 5, // int 默认为0
        }
        this.updateOrder(data);
     },
     // 订单原因
     byRemark(remark){
        this.$alert(remark, '订单驳回原因', {
          confirmButtonText: '确定',
          callback: action => {}
        });
     },

      // 修改
      async updateOrder(data){
        let tc = this.$message({message: '加载中',type: 'warning',duration: 0});
        // 提交
        let postUrl = '/javaApi/order/update';
        let resolveData = await Host.HostPost(postUrl,data);
        tc.close();
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        await this.getList();
        this.getOrderStateCount();
        this.$message({message:  resolveData.msg,type: 'success'});
      },
      // 日期筛选
      async electDate(value,oldValue){
        try {
            if (value === null && oldValue === null) return this.handleClear();
            const start = this.DateTimeString(this.dateValue[0])
            const end = this.DateTimeString(this.dateValue[1])
            const date = `${start}~${end}`;
            this.tableLoading = true;
            let postUrl = `/javaApi/order/dateSearch?value=${date}&state=${this.label}&page=${this.pageCount}`;
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
            if(!resolveData.data) return;
            let arr = await this.setOrderList(resolveData.data)
            this.tableData = await Host.setList(arr);
            this.total = resolveData.total
            this.tableLoading = false;

        } catch (error) {
            this.handleClear();
        }
      },
      // 打岔
      handleClear(){
        this.changePage(1);
      },
      // 搜索
      async searchBtn(){
        this.tableLoading = true;
        let postUrl = `javaApi/order/search?value=${this.input}&page=${this.pageCount}`;
        let resolveData = await Host.HostGet(postUrl);
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        if(!resolveData.data) return;
        let arr = await this.setOrderList(resolveData.data)
        this.tableData = await Host.setList(arr);
        this.total = resolveData.total
        this.tableLoading = false;
      },
      // 复制文字
      copyText(text,msg){
        Method.copyText(text,msg);
        this.$message({message: '已复制' + msg,type: 'success'});
      },
      // 遍历数组
      setOrderList(data) {
        let arr = [];
        return new Promise((resolve, reject) => {
            // 构造包含所有订单详情 Promise 的数组
            let orderDetailPromises = data.map(i => {
                let orderList = i.orderList.split("&");
                let o = [];

                for (var j of orderList) {
                    o.push({ serialNumber: j.split("_")[0], shopNum: j.split("_")[1] })
                }
                let deta = this.getOrderDeta(i.orderId);
                let obj = {
                    regTime: i.saveTime,
                    money: i.money,
                    comId: i.comId,
                    comName: i.comName,
                    operationName: i.operationName,
                    orderId: i.orderId,
                    beName: i.beName,
                    orderInfo: [{ orderId: i.orderId, waybillNumber: i.waybillNumber, dispatchingType: i.dispatchingType, site: i.site, sign: i.sign, pay: i.pay }],
                    dispatchingType: i.dispatchingType,
                    orderList: i.orderList,
                    remark: i.remark,
                    state: i.state,
                }
                console.log(obj)
                arr.push(obj)

                return deta; // 返回订单详情 Promise
            });

            // 等待所有订单详情 Promises 解决
            Promise.all(orderDetailPromises)
                .then(orderDetails => {
                    // 将订单详情分配给对应的 obj
                    for (let i = 0; i < orderDetails.length; i++) {
                        arr[i].shopOrder = orderDetails[i];
                    }
                    console.log(arr);
                    resolve(arr); // 解决最终的 Promise
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },

    // 获取订单详情
    getOrderDeta(orderId) {
        return new Promise((resolve, reject) => {
            let postUrl = `/javaApi/order/deta?orderId=${orderId}`;
            Host.HostGet(postUrl).then((resolveData) => {
                if (!resolveData.data) resolve([]);
                resolve(JSON.parse(resolveData.data));
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },
     // 获取订单状态数量
     getOrderStateCount() {
            let postUrl = `/api/order/count`;
            Host.HostPost(postUrl).then((resolveData) => {
                console.log("状态数量",resolveData.data)
                if (!resolveData.data){this.orderStateCount = {}}
                this.orderStateCount = resolveData.data;
            })
            .catch(error => {});
    },
    //遍历时间
    DateTimeString(D){
        var date = new Date(D);
        var year = date.getFullYear();
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        return year + "-" + month + "-" + day;
      }
    }
} 
</script>

<style>
    .searchBox {
        display: flex;
    }
     .searchBox .searchBtn {
        margin-left: 20px;
        border-radius: 5px !important;

    }
    .tab-label {
        display: flex;
        padding: 0 10px;
    }
    .tab-label div {
        height: 40px;
        line-height: 40px;
        width: 70px;
    }
    .tab-label .badge {
        position:absolute;
        left: 70px;
    }
</style>
<style scoped>
    .orderNew {
        --el-table-tr-bg-color: none;
        --el-table-row-hover-bg-color: none;
        color: #fff;
        background: #3e6b27;
    }
    .orderUsed {
        --el-table-tr-bg-color: none;
        --el-table-row-hover-bg-color: none;
        color: #fff;
        background: #7d5b28;
    }
</style>
