// 封装请求
import axios from 'axios';
import { ElMessage } from 'element-plus';
import Router from '@/router'
export default {
    HostGet,
    HostPost,
    load,
    setList,
}
var load = true;
function HostGet(url){
    return new Promise((resolve,reject)=>{
        if(load){
            this.load = false;
            try {
                axios({
                    method: 'GET',
                    url: url,
                    headers:{code:localStorage.getItem('token')},        
                    }).then((res)=>{
                    if(res.data.code == 205){
                        localStorage.removeItem('token');
                        ElMessage({message: '登录已过期!',type: 'warning'});
                        Router.push('/login');
                        return;
                    }
                    this.load = false;
                    setTimeout(() => {
                        return resolve(res.data);
                    }, 200);
                })
            } catch(error){
                this.ErrorMsg(error);
                reject(error)
            }
        }
    })
}
function HostPost(url,data){
    return new Promise((resolve,reject)=>{
        if(load){
            this.load = false;
            try {
                axios({
                    method: 'POST',
                    url: url,
                    headers:{code:localStorage.getItem('token')},
                    data: data,     
                    }).then((res)=>{
                    if(res.data.code == 205){
                        localStorage.removeItem('token');
                        ElMessage({message: '登录已过期!',type: 'warning'});
                        Router.push('/login')
                        return;
                    }
                    this.load = false;
                    setTimeout(() => {
                        return resolve(res.data);
                    }, 200);
                })
            } catch(error){
                this.ErrorMsg(error);
                reject(error)
            }
        }
    })
}


// 时间格式化
function setList(list){
    return new Promise((resolve,resjest)=>{
        try {
            for(let element of list){
                const date = new Date(element.regTime * 1000);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');  // 注意月份从 0 开始，需要加 1
                const day = String(date.getDate()).padStart(2, '0');
                element.timer = element.regTime;
                element.regTime = `${year}-${month}-${day}`;
            }
            return resolve(list); 
        } catch(error){resjest(error)}
    })
}

function ErrorMsg(error){
    if (error.response) {
        ElMessage({message: '服务器错误，请稍后再试!',type: 'warning'});
      } else if (error.request) {
        ElMessage({message: '网络出现问题，请检查您的网络连接',type: 'warning'});
      } else {
        ElMessage({message: '发生了意外错误，请重试',type: 'warning'});
      }
}