<template>
    <div id="app" class="">
       <div class="loginBox">
            <div class="title">「万象后台管理系统」</div>
            <div class="login-from">
                <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-position="left"
                label-width="70px">
                
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input type="password" placeholder="请输入手机号" v-model="ruleForm.phone" autocomplete="off" />
                </el-form-item>
                </el-form>
                <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
            </div>
       </div>
    </div>
</template>

<script>
import Host from "../store/host";
import { mapMutations } from 'vuex';
export default {
    name: 'LoginView',
    data(){
        return {
            ruleForm: {
                name: "",
                phone: "",
            },
            rules: {
                name: [
                { required: true, message: "姓名不能为空！", trigger: "blur" },
                ],
                phone: [
                { required: true, message: "手机号不能为空！", trigger: "blur" },
                ],
            },
        }
    },
    mounted() {
        
    },
    methods: {
        ...mapMutations('auth', ['SET_TOKEN']),
        // 请求链接
        
        // 登陆
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 发送请求
                    this.getlogin();
                } else {
                    this.$message({message: '请输入登录信息',type: 'danger'});
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
       
        // 监听登录
        async getlogin(){
            let postUrl = `/api/login`;
            let result = await Host.HostPost(postUrl,this.ruleForm);
            if(result.code != 200) return;
            // 数据
            let resolveData = result.data;
            this.$message({message: '登录成功',type: 'success'});
            localStorage.setItem('token',resolveData.token)
            localStorage.setItem('id',resolveData.id)
            localStorage.setItem('cid',resolveData.cid)
            localStorage.setItem('name',resolveData.name)
            localStorage.setItem('headImg',resolveData.headImg)
            localStorage.setItem('regTime',resolveData.regTime)
            localStorage.setItem('grade',resolveData.grade)
            localStorage.setItem('beName',resolveData.beName)
            
            if(resolveData.cid == null){
                localStorage.setItem('department','超级管理员');
            } else {
                this.getDepartmemt().then(()=>{
                    setTimeout(()=>{
                        this.$router.push('/dataShow')
                    },2000)
                })
                return;
            }
            setTimeout(()=>{
                this.$router.push('/dataShow')
            },2000)
        },
        
        async getDepartmemt(){
            let postUrl = `/javaApi/department/list`;
            let resolveData = await Host.HostGet(postUrl);
            this.deparmentData = resolveData;
            for(var i of resolveData){
            if(localStorage.getItem('cid') == i.id){
                localStorage.setItem("department",i.name)
            }
            }
        },
    },
}
</script>
<style>
    body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        /* global 94%+ browsers support */
        background: linear-gradient(8deg,#FFFFFF 52.25%,#1155E7 100%),linear-gradient(90deg,#FFF1EB 0%,#ACE0F9 100%); 

        /* safari 5.1+,chrome 10+ */
        background: -webkit-linear-gradient(8deg,#FFFFFF 52.25%,#1155E7 100%),-webkit-linear-gradient(90deg,#FFF1EB 0%,#ACE0F9 100%);

        /* ff 3.6+ */
        background: -moz-linear-gradient(8deg,#FFFFFF 52.25%,#1155E7 100%),-moz-linear-gradient(90deg,#FFF1EB 0%,#ACE0F9 100%);

        /* opera 11.10+ */ 
        background: -o-linear-gradient(8deg,#FFFFFF 52.25%,#1155E7 100%),-o-linear-gradient(90deg,#FFF1EB 0%,#ACE0F9 100%);

        /* ie 10+ */
        background: -ms-linear-gradient(8deg,#FFFFFF 52.25%,#1155E7 100%),-ms-linear-gradient(90deg,#FFF1EB 0%,#ACE0F9 100%);
    }
    .loginBox {
        width: 500px;
        color: #000;
    }
    .title {
        font-size: 50px;
        font-weight: 600;
        font-family: system-ui;
    }
    .login-from {
        width: 350px;
        margin: 55px auto;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 60px 50px;
        border-radius: 20px;
    }
    .loginImgBox>image {
        width: 100%;
        height: 100%;
    }
    .spanT {
        /* margin: 30px 0; */
        color: #fff;
    }
</style>