<template>
    <div id="headBox" class="no-select">
        <!-- 左 -->
         <div class="leftBox">
            <el-icon size="30"><ElementPlus /></el-icon>
         </div>
         <!-- 右 -->
         <div class="rightBox no-select">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="beName + '-' + gradeTitle"
                placement="bottom"
              >
              <el-avatar style="margin: 0 10px;" :size="30" :src="circleUrl" />
          </el-tooltip>
            
          <el-tag>{{ deparment }}</el-tag>
          <el-tag style="margin: 0 10px;" type="danger" @click="quit">退出</el-tag>
         </div>
    </div>
</template>
<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      circleUrl: localStorage.getItem('headImg'),
      cid: localStorage.getItem('cid'),
      grade: localStorage.getItem('grade'),
      gradeTitle: '',
      beName: localStorage.getItem('beName'),
      deparment: localStorage.getItem('department'),
    }
  },
  created(){
    if(this.grade == 1){
      this.gradeTitle = "超级管理";
    } else if(this.grade == 2){
      this.gradeTitle = "部门管理";
    } else {this.gradeTitle = "成员";}
  },
  methods: {
    quit(){
      localStorage.clear();
      this.$router.push('/login')
    },

  }
}
</script>
<style scoped> 
    #headBox {
        display: flex;
        width: calc(100vw - 200px);
        height: 100%;
        background: #fff;
    }
    .leftBox {
        flex:1;
        justify-content: flex-start; 
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .rightBox {       
        justify-content: flex-end;
        display: flex;
        align-items: center;
        padding-right: 20px;
    }
    /* 鼠标 手指 */
    #finger {
        cursor: pointer;
    }


</style>
<style>
    /* 重写 */
   .el-header {
      --el-header-padding: 0 !important;
      --el-header-height: 45px !important;
    }
</style>