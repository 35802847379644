import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import '@/assets/action.css';

const app = createApp(App);

// 导入table报错解决
import '@/store/error/table.js';


app.use(store).use(ElementPlus).use(router).use(()=>{
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component);
    }
});

app.mount('#app');