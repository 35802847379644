<!--

        订单管理 

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;" @tab-change="btnPane" v-model="label">
            <!-- 业务员 -->
            <el-tab-pane label="待审核" name="2" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>待审核</div><el-badge v-if="orderStateCount.state2 != 0" class="badge" :value="orderStateCount.state2" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

          

            <el-tab-pane label="进行中" name="3" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>进行中</div><el-badge v-if="orderStateCount.state3 != 0" class="badge" :value="orderStateCount.state3" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>
           
            <!-- 财务 -->
            <el-tab-pane label="待闭环" name="4" v-if="department.includes('财务') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>待闭环</div><el-badge v-if="orderStateCount.state4 != 0" class="badge" :value="orderStateCount.state4" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

           

            <!-- 公共 -->
            <el-tab-pane label="已完成" name="5">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已完成</div>
                    </span>
                </template>
            </el-tab-pane>

            <el-tab-pane label="已驳回" name="0" v-if="department.includes('运营') || grade == 1">
                <template v-slot:label>
                    <span class="tab-label">
                        <div>已驳回</div><el-badge v-if="orderStateCount.reject != 0" class="badge" :value="orderStateCount.reject" type="danger"></el-badge>
                    </span>
                </template>
            </el-tab-pane>

            <!-- 订单号/运单号搜索 -->
            <div class="searchBox">
                <div class="block" style="margin-right: 10px;">
                    <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    @change="electDate"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <el-input v-model="input" style="width: 400px;" placeholder="搜索工单号/运单号" @clear="handleClear" clearable/>
                <el-button style="margin-left: 10px;" type="primary" @click="searchBtn" circle><el-icon><Search /></el-icon></el-button>
            </div>

            <div style="height: 20px;"></div>
            <!-- 订单号数据 -->
              <el-table height="530" :data="tableData" :border="true"  v-loading="tableLoading" stripe>
                    <el-table-column type="expand" style="padding: 0 10px;">
                        <template #default="props">
                            <div m="4">
                            <div style="margin: 10px; margin-top: 0;">
                                <el-table :data="props.row.orderInfo" style="width: 100%" :border="true">

                                    <el-table-column label="订单号" width="80">
                                        <template #default="scope">
                                            <el-tooltip class="box-item" effect="dark" :content="scope.row.orderId" placement="top">
                                                <el-tag @click="copyText(scope.row.orderId,'订单号')">Copy</el-tag>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="收货方式"  width="100" align="center">
                                        <template #default="scope">
                                            <el-tag v-if="(scope.row.dispatchingType == 1)">快递</el-tag>
                                            <el-tag v-if="(scope.row.dispatchingType == 2)">上门</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="快递单号" width="300" align="center">
                                        <template #default="scope">
                                            <div v-if="scope.row.waybillNumber">
                                                <el-tooltip class="box-item" effect="dark" :content="scope.row.waybillNumber" placement="top">
                                                    <div @click="copyText(scope.row.waybillNumber,'运单号')" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.waybillNumber}}</div>
                                                </el-tooltip>
                                            </div>
                                            <el-text v-else>无</el-text>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="回收供应商地址" align="center">
                                        <template #default="scope">
                                            <el-tooltip class="box-item" effect="dark" :content="scope.row.site" placement="top" v-if="scope.row.dispatchingType != 3 && scope.row.site.length != 0">
                                                <div @click="copyText(scope.row.site,'回收供应商地址')" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.site}}</div>
                                            </el-tooltip>
                                            <el-text v-else>无</el-text>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="财务审核"  width="120" align="center">
                                        <template #default="scope">
                                            <el-tag type="info" v-if="(scope.row.sign == 0 || scope.row.sign == 1)">待审核</el-tag>
                                            <el-tag type="success" v-else-if="(scope.row.sign == 2)">通过</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="付款方式"  width="120" align="center">
                                        <template #default="scope">
                                            <el-text>{{scope.row.pay}}</el-text>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- 订单列表 -->
                            <div style="margin: 10px;" v-for="(order, index) in props.row.shopOrder" :key="index">
                                <el-table :border="true" :data="order.list" :class="order.label == '全新' ? 'orderNew':'orderUsed'" v-if="order.list.length != 0">
                                    <el-table-column label="标签" align="center" width="100">
                                        <template #default>
                                            <el-text style="color: #fff;">{{order.label}}</el-text>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="产品描述" prop="describe" align="center" />
                                    <el-table-column label="回收价" prop="price" align="center" width="200" />
                                    <el-table-column label="数量" prop="count" width="200" align="center" show-summary />
                                    
                                </el-table>
                            </div>
                            <!-- 订单列表end -->
                            </div>
                        </template>
                    </el-table-column>
                
                <el-table-column label="商家ID" width="80">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="dark" :content="scope.row.comId.toString()" placement="top">
                            <el-tag @click="copyText(scope.row.comId ,'商家ID')">Copy</el-tag>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" prop="regTime" width="120" /> 
                <el-table-column label="总金额" prop="money" width="120" align="center"/>
                <el-table-column label="姓名" prop="beName" width="100" align="center"/> 
                <el-table-column label="企业名称" prop="comName" />
                <el-table-column label="业务员" prop="operationName" width="120" align="center"/>
                <el-table-column label="状态" width="120" align="center">
                        <template #default="scope">
                            <el-tag type="warning" v-if="(scope.row.state == 2)">待审核</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 3)">进行中</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 4 && scope.row.sign == 1)">待闭环</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state  == 4 && scope.row.sign == 2)">已完成</el-tag>
                            <el-tag type="danger" v-else>已驳回</el-tag>
                        </template>
                    </el-table-column>

                <el-table-column :label="label == 0 ? '原因':'操作'" width="120" align="center">
                    <template #default="scope">
                        <el-text v-if="(label == 2 || label == 3) && department.includes('财务')">无</el-text>
                        <el-dropdown v-else-if="label == 1 || label == 2 || label == 3 || label == 4 || label == 6">
                            <span class="el-dropdown-link"><el-button link type="primary" size="small">选项</el-button></span>
                            <template #dropdown>
                            <el-dropdown-menu>
                                <!-- 业务员 -->
                                <div v-if="department.includes('运营') || grade == 1 && label != 11">
                                    <div v-if="(scope.row.state == 2)">
                                        <el-dropdown-item><el-button link type="success" size="small" @click="byPass(scope.row.orderId,scope.row.comId,2)">通过</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="danger" size="small" @click="byReject(scope.row.orderId,scope.row.comId)">驳回</el-button></el-dropdown-item>
                                    </div>
                                    <div v-if="(scope.row.state == 3 && label == 3)">
                                        <el-dropdown-item><el-button link type="primary" size="small" @click="byPass(scope.row.orderId,scope.row.comId,3)">已收货</el-button></el-dropdown-item>
                                        <el-dropdown-item><el-button link type="danger" size="small" @click="byReject(scope.row.orderId,scope.row.comId)">驳回</el-button></el-dropdown-item>
                                    </div>
                                    
                                </div>
                                <!-- 财务 -->
                                <div v-if="department.includes('财务') || grade == 1 && label == 4">
                                    <el-dropdown-item><el-button link type="success" size="small" @click="byPass(scope.row.orderId,scope.row.comId,4)">通过</el-button></el-dropdown-item>
                                    <el-dropdown-item><el-button link type="danger" size="small" @click="byReject(scope.row.orderId,scope.row.comId)">驳回</el-button></el-dropdown-item>
                                </div>

                                <!-- 公共 -->
                                <el-dropdown-item><el-button link type="primary" size="small" @click="getPicList(scope.row.orderId)">取件照片</el-button></el-dropdown-item>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <el-button v-else-if="label == 0" link type="success" size="small" @click="byRemark(scope.row.remark)">查看</el-button>
                        <el-text v-else>无</el-text>
                    </template>
                    </el-table-column>
            </el-table>
            <el-drawer v-model="recyclePicShow" :title="'工单号: ' + orderId" direction="ltr" size="30%">
                <div style="height: 20px;"></div>
                <!-- 图集展示 -->
                    <div class="img-container" v-loading="ImgListLoad">
                        <div>
                            <template v-for="(item,index) in srcList" :key="index">
                                <el-image
                                    style="width: 100px; height: 100px"
                                    :src="item.url"
                                    :zoom-rate="1.3"
                                    :preview-src-list="srcLists"
                                    :initial-index="index"
                                    fit="cover"
                                />
                            </template>
                        </div>
                </div>
            </el-drawer>
            <!-- 分页 -->
            <div style="margin-top: 10px; display: flex; justify-content: center;">
                <Pagination :total="total" :page-size="pageSize"  @changePage="changePage"/>
            </div>
        </el-tabs>
    </div>
</template>

<script>
import Pagination from '../components/PageinationView.vue';
import Host from "../store/host";
import Method from "../store/publicMethod";
export default {
    components: {
        Pagination
    },
    data(){
        return {
            tableData: [],
            orderStateCount:{},
            label: "2",
            tableLoading: false,
            pageSize: 1,
            pageCount: 1,
            total: 0,
            input: "",
            grade: localStorage.getItem('grade'),
            department: localStorage.getItem('department'),
            waybillNumber: '', // 运单号
            dialogType: 1,
            dialogFormVisible: true,
            dateValue: "",
            recyclePicShow: false,
            ImgListLoad: null,
            orderId: null,
        }
       
    },
    mounted() {
       
    },
    created(){
        this.getOrderStateCount();
        this.$nextTick(()=>{});
        if(this.department.includes('财务')){this.label = "4"} else {this.label = "2"}
        this.changePage(1);
    },
    methods: {
      // 分页
      changePage(num){
        this.pageCount = num;
        this.getList();
      },
      // 获取列表
      async getList(){
        this.tableLoading = true;
        let postUrl = `/javaApi/recycle/select?state=${this.label}&page=${this.pageCount}`;
        let resolveData = await Host.HostGet(postUrl);
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        if(!resolveData.data) return;
        let arr = await this.setOrderList(resolveData.data)
        console.log(resolveData.data)
        this.tableData = await Host.setList(arr);
        this.total = resolveData.total
        this.tableLoading = false;

      },
      btnPane(e){
        this.label = e;
        this.dateValue = "";
        this.changePage(1);
      },

      // 审核
      byPass(orderId,comId,state){
        let data = new Object();
        data.orderId = orderId;
        data.state = state;
        data.comId = comId;
        this.updateOrder(data);
      },

    // 驳回
    byReject(orderId,comId){
        this.$prompt('', '驳回', {
            confirmButtonText: '确认驳回',
            cancelButtonText: '取消',
            inputPlaceholder: '请输入驳回原因',
            inputPattern:  /^.{1,15}$/,
            inputErrorMessage: '文本长度不合法'
            }).then(async({ value }) => {
                let data = {
                    orderId: orderId,
                    comId:comId,
                    remark: value,
                    state: 0,
                }
                this.updateOrder(data);
            }).catch(() => {      
        });
    },
    // 订单原因
    byRemark(remark){
        this.$alert(remark, '订单驳回原因', {
            confirmButtonText: '确定',
            callback: action => {}
        });
    },

    // 修改
    async updateOrder(data){
        let tc = this.$message({message: '加载中',type: 'warning',duration: 0});
        // 提交
        let postUrl = '/javaApi/recycle/audit';
        let resolveData = await Host.HostPost(postUrl,data);
        tc.close();
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        await this.getList();
        this.getOrderStateCount();
        this.$message({message:  resolveData.msg,type: 'success'});
    },
    // 日期筛选
    async electDate(value,oldValue){
        try {
            if (value === null && oldValue === null) return this.handleClear();
            const start = this.DateTimeString(this.dateValue[0])
            const end = this.DateTimeString(this.dateValue[1])
            const date = `${start}~${end}`;
            this.tableLoading = true;
            let postUrl = `/javaApi/recycle/dateSearch?value=${date}&state=${this.label}&page=${this.pageCount}`;
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
            if(!resolveData.data) return;
            let arr = await this.setOrderList(resolveData.data)
            this.tableData = await Host.setList(arr);
            this.total = resolveData.total
            this.tableLoading = false;

        } catch (error) {
            this.handleClear();
        }
    },
      // 打岔
      handleClear(){
        this.changePage(1);
      },
      // 搜索
      async searchBtn(){
        this.tableLoading = true;
        let postUrl = `/javaApi/recycle/search?value=${this.input}&page=${this.pageCount}`;
        let resolveData = await Host.HostGet(postUrl);
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        if(!resolveData.data) return;
        let arr = await this.setOrderList(resolveData.data)
        this.tableData = await Host.setList(arr);
        this.total = resolveData.total
        this.tableLoading = false;
      },
      // 复制文字
      copyText(text,msg){
        Method.copyText(text,msg);
        this.$message({message: '已复制' + msg,type: 'success'});
      },
      // 遍历数组
      setOrderList(data) {
        let arr = [];
        return new Promise((resolve, reject) => {
            // 构造包含所有订单详情 Promise 的数组
            let orderDetailPromises = data.map(i => {
                let deta = this.getOrderDeta(i.orderId);
                let obj = {
                    regTime: i.saveTime,
                    money: i.money,
                    comId: i.comId,
                    comName: i.comName,
                    operationName: i.operationName,
                    orderId: i.orderId,
                    beName: i.beName,
                    orderInfo: [{ orderId: i.orderId, waybillNumber: i.waybillNumber, dispatchingType: i.dispatchingType, site: i.site, sign: i.sign, pay: i.pay }],
                    dispatchingType: i.dispatchingType,
                    orderList: i.orderList,
                    remark: i.remark,
                    sign: i.sign,
                    state: i.state,
                }
                console.log(obj)
                arr.push(obj)
                
                return deta; // 返回订单详情 Promise
            });

            // 等待所有订单详情 Promises 解决
            Promise.all(orderDetailPromises)
                .then(orderDetails => {
                    // 将订单详情分配给对应的 obj
                    for (let i = 0; i < orderDetails.length; i++) {
                        arr[i].shopOrder = orderDetails[i];
                    }
                    console.log(arr);
                    resolve(arr); // 解决最终的 Promise
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },

    // 获取订单详情
    getOrderDeta(orderId) {
        return new Promise((resolve, reject) => {
            let postUrl = `/javaApi/recycle/deta?orderId=${orderId}`;
            Host.HostGet(postUrl).then((resolveData) => {
                if (!resolveData.data) resolve([]);
                resolve(JSON.parse(resolveData.data));
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },
     // 获取订单状态数量
     getOrderStateCount() {
            let postUrl = `/javaApi/recycle/count`;
            Host.HostPost(postUrl).then((resolveData) => {
                console.log("状态数量",resolveData.data)
                if (!resolveData.data){this.orderStateCount = {}}
                this.orderStateCount = resolveData.data;
            })
            .catch(error => {});
    },
    //遍历时间
    DateTimeString(D){
        var date = new Date(D);
        var year = date.getFullYear();
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        return year + "-" + month + "-" + day;
    },
     // 图集
     getPicList(orderId){
        this.orderId = orderId;
        this.recyclePicShow = true;
        this.fileImgList(orderId);
   
    },
    // 获取图集
    async fileImgList(orderId){
        this.ImgListLoad = true;
        let postUrl = `/javaApi/recycle/picList?orderId=${orderId}`;
        let resolveData = await Host.HostGet(postUrl);
        this.ImgListLoad = false;
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg, type: 'warning'});
        const data = []
        const datas = []
        let obj;
        // 遍历列表
        resolveData.data.forEach(element => {
            obj = new Object();
            obj.id  = element.id;
            obj.fileName  = element.fileName;
            obj.url = "http://wximg.slenb.cn/recycle/" + element.fileName;
            data.push(obj);
            datas.push(obj.url);
        })
        this.srcList = data;
        this.srcLists = datas;
    },
    }
} 
</script>

<style>
    .searchBox {
        display: flex;
    }
     .searchBox .searchBtn {
        margin-left: 20px;
        border-radius: 5px !important;

    }
    .tab-label {
        display: flex;
        padding: 0 10px;
    }
    .tab-label div {
        height: 40px;
        line-height: 40px;
        width: 70px;
    }
    .tab-label .badge {
        position:absolute;
        left: 70px;
    }
</style>
<style scoped>
    .orderNew {
        --el-table-tr-bg-color: none;
        --el-table-row-hover-bg-color: none;
        color: #fff;
        background: #3e6b27;
    }
    .orderUsed {
        --el-table-tr-bg-color: none;
        --el-table-row-hover-bg-color: none;
        color: #fff;
        background: #7d5b28;
    }
</style>
