<template>
    <div class="leftNav no-select">
      <el-menu :default-active="router" :index="router" router class="el-menu-vertical-demo" text-color="#fff" active-text-color="#ffd04b">   
        <div class="login">
          <h4>三菜回收管理系统</h4>
        </div>

        <el-menu-item index="/dataShow" v-if="show">
        <el-icon><PieChart /></el-icon>
        <template #title>数据中心</template>
        </el-menu-item>

        <el-menu-item index="/Agreement" v-if="grade == 1 && show">
        <el-icon><Document /></el-icon>
        <template #title>文档协议</template>
        </el-menu-item>

        <el-menu-item index="/ComDepartmemt" v-if="grade != 3  && show">
          <el-icon><OfficeBuilding /></el-icon>
          <template #title>企业部门</template>
        </el-menu-item>

        <el-menu-item index="/wechatmp" v-if="grade != 3  && show">
          <el-icon><Paperclip /></el-icon>
          <template #title>信息配置</template>
        </el-menu-item>
        
        <el-menu-item index="/users" v-if="(grade == 1 || department.includes('运营'))  && show">
          <el-icon><User /></el-icon>
          <template #title>用户管理</template>
        </el-menu-item>
        
        <!-- <el-menu-item index="/mc" v-if="(grade == 1 || department.includes('运营'))  && show">
          <el-icon><Grid /></el-icon>
          <template #title>经营体管理</template>
        </el-menu-item> -->

        <el-sub-menu index="1-3" v-if="show">
        <template #title><el-icon><List /></el-icon><span>订单管理</span></template>
          <el-menu-item index="/order"> 
            <template #title>回收订单</template>
          </el-menu-item>

          <!-- <el-menu-item index="/recycle"> 
            <template #title>回采订单</template>
          </el-menu-item> -->
        </el-sub-menu>

        <el-sub-menu index="1-5" v-if="show">
        <template #title><el-icon><Box /></el-icon><span>仓库管理</span></template>
          <!-- <el-menu-item index="/order"> 
            <template #title>零售仓</template>
          </el-menu-item> -->

          <el-menu-item index="/recyclingHouse"> 
            <template #title>回收仓</template>
          </el-menu-item>
        </el-sub-menu>


        <el-sub-menu index="1-4" v-if="(grade == 1 || department.includes('运营'))  && show">
          <template #title><el-icon><Histogram /></el-icon><span>报价管理</span></template>
            <el-menu-item index="/navConfig">
              <template #title>产品目录</template>
            </el-menu-item>

            <el-menu-item index="/setPrice">
              <template #title>产品报价</template>
            </el-menu-item>
          </el-sub-menu>
      </el-menu>
      

 
    </div>
</template>

<script>
  import { toRaw} from 'vue'
  import { useRouter } from 'vue-router';
  export default {
    name: "leftNav",
    data(){
      return {
          show: false,
          router: null,
          grade: localStorage.getItem('grade'),
          department: localStorage.getItem('department'),
      }
    },
    methods:{
        get(){
          let routers = useRouter() 
          this.router = toRaw(routers).currentRoute.value.fullPath;
        }
 	  },
    mounted() {
      this.grade = localStorage.getItem('grade');
      this.department = localStorage.getItem('department');
      this.show = true;
    },
    created(){
      this.get();
    }
  }
</script>

<style>
  /* 重写 */
  .el-sub-menu__title:hover {
      background-color: #6464649f !important;
  }
  .el-menu {
    background: #18222c !important;
    border-right: none !important;
  }
  /*  */
  .el-menu-item-group__title {
    padding: 0 !important;
  }
  .el-menu-item:hover {
    background-color: #6464649f !important;
  }
  .el-menu-item.is-active {
    background: #363637 !important;
  }
</style>

<style scoped>
   .el-menu-vertical-demo {
    height: 100vh;
    background-color: #18222c;
    color: rgba(255, 255, 255, 0.706);
  }
  .leftNav {
    cursor: pointer;
    width: 180px;
  }
  .login {
    width: 100%;
    height: 60px;
    /* background: #409EFF; */
    /* color: #fff; */
    line-height: 60px;
    text-align: center;
  }
</style>