<!--

        协议管理

-->
<template>
    <div class="box">
        <div style="width: 100%; height: 50px;">
            <el-tabs v-model="label" type="border-card"  @tab-change="btnPane">
                <el-tab-pane label="基本信息配置" name="mp1"></el-tab-pane>
                <!-- <el-tab-pane label="万象回收报价" name="mp2"></el-tab-pane> -->

                 <!-- 编辑 -->
                <div class="ckeditorBox">
                    <!-- 万象小时达 -->
                    <div v-show="label == 'mp1'">
                        <el-form :model="salesmanData" label-width="auto" style="max-width: 600px">
                            <el-form-item label="微信号">
                                <el-input v-model="salesmanData.weChat" />
                            </el-form-item>
                            <el-form-item label="报单教程链接">
                                <el-input v-model="salesmanData.phone" />
                            </el-form-item>
                            <el-form-item label="小程序分享标题">
                                <el-input v-model="salesmanData.shareTitle" />
                            </el-form-item>

                            <el-form-item label="报单付款方式">
                                <el-input v-model="salesmanData.paymentMethods" />
                            </el-form-item>


                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">更新</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- 万象回收报价 -->
                    <div v-show="label == 'mp2'">
                        <!-- banner -->
                        <el-image style="width: 500px;" :src="src"></el-image>
                    </div>
                </div>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Host from "../../store/host"
export default {
    data() {
      return {
        label: 'mp1',
        salesmanData: {},
        src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
      }
    },
    mounted(){
        this.btnPane(this.label)
    },
    methods: {
        saveContent(){
            // 获取内容
            this.$refs.iframe.contentWindow.content();
            window.addEventListener('message',this.getiframeMsg)
        },
        // 标签点击
        async btnPane(e){
            this.label = e;
            if(e == 'map1'){
                // 请求数据
                let getUrl = '/api/getSalesman'
                let resolveData = await Host.HostGet(getUrl);
                console.log(resolveData);
                this.salesmanData.weChat = resolveData.data.salesmanWechat;
                this.salesmanData.phone = resolveData.data.salesmanPhone;
                this.salesmanData.shareTitle = resolveData.data.shareTitle;
                this.salesmanData.paymentMethods = resolveData.data.paymentMethods;
                this.ckeditorLoading = true;
                this.textLoading = false;
            } else {
                this.getAgreement();
                this.ckeditorLoading = false;
            }

        },
        // 更新业务员联系方式
        async onSubmit(){
            let postUrl = '/api/setSalesman';
            let data = {weChat: this.salesmanData.weChat, phone:this.salesmanData.phone,shareTitle: this.salesmanData.shareTitle,paymentMethods: this.salesmanData.paymentMethods};
            if(data.weChat.length == 0 || data.phone.length == 0 || data.shareTitle.length == 0 || data.paymentMethods.length == 0) return this.$message({message: '内容不能为空',type: 'error'});
            let resolveData = await Host.HostPost(postUrl,data);
            if(resolveData.code != 200) return this.$message({message: '更新失败',type: 'error'});
            this.$message({message: '更新成功',type: 'success'});
            return;
        },
        // 获取业务员
        async getAgreement(){
            this.textLoading = true;
            let getUrl = '/api/getSalesman';
            let resolveData = await Host.HostGet(getUrl);
            this.salesmanData.weChat = resolveData.data.salesmanWechat;
            this.salesmanData.phone = resolveData.data.salesmanPhone;
            this.salesmanData.shareTitle = resolveData.data.shareTitle;
            this.salesmanData.paymentMethods = resolveData.data.paymentMethods;
        },
    }
}
</script>


<style>
    
    .box {
        display: flex;
        /* flex-wrap: wrap; */
    }
    .ckeditorBox {
        height: calc(100vh - 190px);
    }

    /* 编辑器下方按钮样式 */
    .buttomBox {
      margin-top: 10px;
      width: 100%;
    }

    .buttomBox button{
        float: right;
        margin-right: 20px;
    }
    .iframeBox {
        width: 100%; height: 75vh;
    }





  /* 编辑器滚动条 */
    .ck-content {
        width: 100%;
        height: calc(100vh - 190px);
        overflow-y: scroll;
    }

    /* 滚动条整体样式 */
    .ck-content::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    /* 滚动条轨道样式 */
    .ck-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    /* 滚动条滑块样式 */
    .ck-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }

    /* 鼠标放在滚动条上的样式 */
    .ck-content::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }
</style>
