<template>
    <div class="common-layout">
        <el-container>
          <el-aside width="180px"><leftNav/></el-aside>
          <el-container>
              <el-header><HeaderNav/></el-header>
              <el-main>
                  <div class="mainBox no-select">
                    <router-view/>
                  </div>
              </el-main>
          </el-container>
        </el-container>
    </div>
</template>

<script>
import LeftNav from '@/components/ActionMain/LeftNav.vue';
import HeaderNav from '@/components/ActionMain/HeaderNav.vue';

export default {
  name: 'AcrtionMain',
  components: {
    LeftNav,
    HeaderNav,
  }
}
</script>
<style>
    /* 重写 */
   .el-main {
      overflow: hidden;
      --el-main-padding: 0px !important;
      height: calc(100vh - 60px) !important;
    }
    
</style>
<style scoped>
   .mainBox {
      display: flex;
      width: calc(100vw - 200px);
      height: calc(100vh - 48px);
      background: #efefef;
      padding-top: 2px;
      overflow: hidden;
    }
    .common-layout {
      /* background: #f1efef; */
      /* background-color: #18222c; */
    }
</style>
