<!--

        回收仓库管理 

-->
<template>
  <div class="box">
      <el-tabs type="border-card" style="width: 100%;" @tab-change="btnPane" v-model="label">
          <div style="display: flex;">
            <el-input size="small" type="number" style="width: 120px;" placeholder="输入仓库编号" v-model="ruleForm.id" clearable></el-input>
            <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入仓库名" v-model="ruleForm.house" clearable></el-input>
            <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入收件人姓名" v-model="ruleForm.name" clearable></el-input>
            <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入手机号" v-model="ruleForm.phone" clearable></el-input>
            <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入仓库地址" v-model="ruleForm.site" clearable></el-input>
            <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入回收类目" v-model="ruleForm.resMenu" clearable></el-input>
            <el-button style="margin-left: 10px;" size="small" type="primary" @click="addhouse()">添加仓库</el-button>
          </div>
          <div style="height: 20px;"></div>
          <!-- 仓库地址 -->
            <el-table height="730" :data="tableData" :border="true"  v-loading="tableLoading" stripe>
              <el-table-column label="仓库编号" prop="id" width="120"  align="center">
                  
              </el-table-column>
              <el-table-column label="地区" prop="house" width="120"  align="center"/> 
              <el-table-column label="姓名" prop="name" width="120" align="center"/>
              <el-table-column label="手机号" prop="phone" width="130" align="center"/> 
              <el-table-column label="地址" prop="site" />
              <!-- <el-table-column label="回收类目" prop="operationName" width="120" align="center"/> -->
              <el-table-column label="回收类目" width="120" align="center">
                  <template #default="scope">
                    <el-button link type="primary" size="small" @click="lookResMenu(scope.row.id,scope.row.resMenu)">查看</el-button>
                  </template>
              </el-table-column>  
              <el-table-column label="状态" width="120" align="center">
                  <template #default="scope">
                    <el-switch v-model="scope.row.switch" @click="setSwitch(scope.row.id,scope.row.switch)">
                    </el-switch>
                  </template>
              </el-table-column>
              <el-table-column label="排序" width="120" align="center">
                  <template #default="scope">
                    <el-button link type="primary" size="small" @click="moveHouse(scope.row.id,'t')">上移</el-button>
                    <el-button link type="primary" size="small" @click="moveHouse(scope.row.id,'b')">下移</el-button>
                  </template>
              </el-table-column> 
              <el-table-column label="操作" width="120" align="center">
                  <template #default="scope">
                    <el-button link type="danger" size="small" @click="delHouse(scope.row.id)">删除</el-button>
                  </template>
              </el-table-column>        
          </el-table>


          <!-- 回收类目 -->
          
          <el-dialog v-model="recyclePicShow" title="修改" width="500">
          <el-form :model="form">
            <el-form-item label="回收类目" :label-width="formLabelWidth">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="form.resMenu"
                autocomplete="off">
              </el-input>
            </el-form-item>
            
             
          </el-form>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="recyclePicShow = false">Cancel</el-button>
              <el-button type="primary" @click="setResMenu">
                Confirm
              </el-button>
            </div>
          </template>
        </el-dialog>
          <!-- 分页 -->
          <div style="margin-top: 10px; display: flex; justify-content: center;">
              <Pagination :total="total" :page-size="pageSize"  @changePage="changePage"/>
          </div>
      </el-tabs>
  </div>
</template>

<script>
import Pagination from '../../components/PageinationView.vue';
import Host from "../../store/host";
import Method from "../../store/publicMethod";
export default {
  components: {
      Pagination
  },
  data(){
      return {
          tableData: [],
          tableLoading: false,
          pageSize: 1,
          pageCount: 1,
          total: 0,
          recyclePicShow: false,
          form: {
            id: "",
            resMenu: "",
            type: "",
            state: "",
          },
          ruleForm: {  
            id: '',  
            house: "",  
            name: '',  
            phone: "",  
            site: "",  
            resMenu: '',  
          }
      }
     
  },
  mounted() {
     
  },
  created(){
     // 刷新list
     this.getList();
  },
  methods: {
    // 获取列表
    async getList(){
      this.tableLoading = true;
      let postUrl = `/recycleApi/house/get`;
      let resolveData = await Host.HostGet(postUrl);
      this.tableLoading = false;
      if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
     
      let arr = new Array();  
      for(let i = 0; i < resolveData.data.length; i++){ // 注意这里修正为 length  
        let obj = {}; // 或者使用 let obj = new Object(); 但使用 {} 是更常见的写法  
        obj.switch = resolveData.data[i].state === 1 ? true : false; // 使用了 === 进行严格比较  
        // 如果您需要保留原始对象的所有其他属性，您可以这样做：  
        for (let key in resolveData.data[i]) {  
          if (Object.prototype.hasOwnProperty.call(resolveData.data[i], key) && key !== 'state') {  
            obj[key] = resolveData.data[i][key];  
          }  
        }
        arr.push(obj);  
      }  
      arr = arr.sort((a, b) => a.sort - b.sort); 
      this.tableData = arr;
    },
    // 添加仓库
    async addhouse(){
      this.ruleForm.id = Number(this.ruleForm.id)
      if (!this.validateData(this.ruleForm)) {
          this.$message({message: "请检查仓库信息",type: 'danger'});
      } else {
          // 提交
          let postUrl = '/recycleApi/house/add';
          let resolveData = await Host.HostPost(postUrl,this.ruleForm);
          if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
          this.$message({message: resolveData.msg,type: 'success'});
          // 刷新list
          this.getList();
      }
    
    },
    // 查看类目
    lookResMenu(id,resMenu){
      this.form.id = id;
      this.form.resMenu = resMenu;
      this.recyclePicShow = true;
    },  
    // 修改类目
    async setResMenu(){
      this.form.id = Number(this.form.id);
      this.form.type = "resMenu";
      if (!this.ifFromData(this.form)) {
        this.$message({message: "请检查类目信息",type: 'danger'});
      } else {
          let postUrl = '/recycleApi/house/update';
          let resolveData = await Host.HostPost(postUrl,this.form);
          if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
          this.$message({message: resolveData.msg,type: 'success'});
          // 刷新list
          this.getList();
          this.recyclePicShow = false;
      }
    },
    // 移动仓库
    async moveHouse(id,e){
      let postUrl = '/recycleApi/house/update';
      let resolveData = await Host.HostPost(postUrl,{id: id,e: e,type: 'sort'});
      if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
      this.$message({message: resolveData.msg,type: 'success'});
      // 刷新list
      this.getList();
      this.recyclePicShow = false;
    },
    // 修改状态
    async setSwitch(id,e){
      console.log(e)
      this.form.id = Number(id);
      this.form.type = "state";
      if(e){this.form.state = 0} else {this.form.state = 1}
      let postUrl = '/recycleApi/house/update';
      console.log(this.form)
      let resolveData = await Host.HostPost(postUrl,this.form);
      if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
      this.$message({message: resolveData.msg,type: 'success'});
      // 刷新list
      this.getList();
      this.recyclePicShow = false;
    },
    async delHouse(id){
      let postUrl = `/recycleApi/house/del?id=${id}`;
      let resolveData = await Host.HostGet(postUrl);
      if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
      this.$message({message: resolveData.msg,type: 'success'});
      // 刷新list
      this.getList();
    },
    // 仓库信息判断
    validateData(data) {  
      console.log(data)
          // 检查id是否为数字且大于0（假设id应该是正整数）  
          if (typeof data.id !== 'number' || data.id <= 0) {   
              this.$message({message: "请输入仓库编号",type: 'danger'});
              return false;  
          }  
        
          // 检查house是否为字符串且非空  
          if (typeof data.house !== 'string' || data.house.trim() === '') {
              this.$message({message: "请输入仓库名称",type: 'danger'});
              return false;  
          }  
        
          // 检查name是否为字符串且非空  
          if (typeof data.name !== 'string' || data.name.trim() === '') {
              this.$message({message: "请输入姓名",type: 'danger'});
              return false;  
          }  
        
          // 检查phone是否为字符串（或者根据你的需要可以是数字或其他验证逻辑）  
          if (typeof data.phone !== 'string' || !/^\d+$/.test(data.phone)) {
              this.$message({message: "手机号有误",type: 'danger'}); 
              return false;  
          }  
        
          // 检查site是否为字符串且非空  
          if (typeof data.site !== 'string' || data.site.trim() === '') {  
              this.$message({message: "请输入地址",type: 'danger'});
              return false;  
          }  
      
          return true;  
      },
      // 仓库信息判断
      ifFromData(data) {  
          // 检查id是否为数字且大于0（假设id应该是正整数）  
          if (typeof data.id !== 'number' || data.id <= 0) {   
              this.$message({message: "请输入仓库编号",type: 'danger'});
              return false;  
          }    
          // 检查site是否为字符串且非空  
          if (typeof data.resMenu !== 'string' || data.resMenu.trim() === '') {  
              this.$message({message: "请输入回收类目",type: 'danger'});
              return false;  
          }  
      
          return true;  
      },
    // 复制文字
    copyText(text,msg){
      Method.copyText(text,msg);
      this.$message({message: '已复制' + msg,type: 'success'});
    },   
  }
} 
</script>

<style>
  .searchBox {
      display: flex;
  }
   .searchBox .searchBtn {
      margin-left: 20px;
      border-radius: 5px !important;

  }
  .tab-label {
      display: flex;
      padding: 0 10px;
  }
  .tab-label div {
      height: 40px;
      line-height: 40px;
      width: 70px;
  }
  .tab-label .badge {
      position:absolute;
      left: 70px;
  }
</style>
<style scoped>
  .orderNew {
      --el-table-tr-bg-color: none;
      --el-table-row-hover-bg-color: none;
      color: #fff;
      background: #3e6b27;
  }
  .orderUsed {
      --el-table-tr-bg-color: none;
      --el-table-row-hover-bg-color: none;
      color: #fff;
      background: #7d5b28;
  }
</style>
