<!--

        企业管理 

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;"  @tab-change="btnPane" v-model="label">
            <el-tab-pane label="正常企业" name="2"></el-tab-pane>
            <el-tab-pane label="待审核" name="1"></el-tab-pane>
            <el-tab-pane label="已封禁" name="0"></el-tab-pane>
            <!-- 用户搜索 -->
            <div class="searchBox">
                <el-input style="width: 360px;" v-model="input" placeholder="可通过用户ID,企业名称,简称,法人,业务员ID搜索" clearable/>
                <el-button style="margin-left: 10px;" type="primary" @click="searchBtn" circle><el-icon><Search /></el-icon></el-button>
                
            </div>

            <div style="height: 20px;"></div>
            
            <!-- 企业数据 -->
            <div style="display: flex; overflow: hidden;">
                <el-table :data="tableData" height="530" v-loading="tableLoading">
                    <el-table-column label="用户ID" width="80">
                        <template #default="scope">
                            <el-tag @click="copyText(scope.row.id,'用户ID')">Copy</el-tag>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="业务员ID" width="80">
                        <template #default="scope">
                            <el-tag @click="copyText(scope.row.id,'业务员ID')">Copy</el-tag>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="compellation" label="业务员" width="100" align="center"/> -->

                    <el-table-column label="企业名称" width="150">
                        <template #default="scope">
                            <el-tooltip class="box-item" effect="dark" :content="scope.row.comName" placement="top">
                                <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.comName}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>

                    <el-table-column label="简称" width="120">
                        <template #default="scope">
                            <el-tooltip class="box-item" effect="dark" :content="scope.row.beName" placement="top">
                                <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.beName}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>

                    <el-table-column prop="compellation" label="姓名" width="110" align="center"/>
                    <el-table-column prop="legalName" label="法人" width="100" align="center"/>
                    <el-table-column label="状态">
                        <template #default="scope">
                            <el-tag type="danger" v-if="(scope.row.state == 0)">封禁</el-tag>
                            <el-tag type="info" v-else-if="(scope.row.state == 1)">待审</el-tag>
                            <el-tag type="success" v-else>正常</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="200">
                    <template #default="scope">
                        <el-dropdown>
                            <span class="el-dropdown-link"><el-button link type="primary" size="small">选项</el-button></span>
                            <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item><el-button link type="primary" size="small" @click="allot(scope.row.id,scope.row.comName)">分配</el-button></el-dropdown-item>
                                <el-dropdown-item><el-button link type="primary" size="small" @click="lookComImg(scope.row.id)">营业执照</el-button></el-dropdown-item>
                                <el-dropdown-item><el-button link type="primary" size="small" @click="getSerivceList(scope.row.id,scope.row.comName)">客服信息</el-button></el-dropdown-item>
               
                                <el-dropdown-item><el-button link type="primary" size="small" @click="copyText(scope.row.cetNumber,'证件编号')">证件编号</el-button></el-dropdown-item>
                                <el-dropdown-item><el-button link type="primary" size="small" @click="getComMember(scope.row.rid)">业务员</el-button></el-dropdown-item>
                                <el-dropdown-item divided>
                                    <el-button v-if="(scope.row.state == 0)" link type="warning" size="small" @click="setMcState(scope.row.id,2)">解封</el-button>
                                    <el-button v-else-if="(scope.row.state == 1)" link type="success" size="small" @click="audit(scope.row.id)">审核</el-button>
                                    <el-button v-else link type="danger" size="small" @click="setMcState(scope.row.id,0)">封禁</el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            
             <!-- 分页 -->
            <div style="margin-top: 10px; display: flex; justify-content: center;">
                <Pagination :total="total" :page-size="pageSize"  @changePage="changePage"/>
            </div>
        </el-tabs>
        <!-- 客服抽屉 -->
         <el-drawer v-model="kfShow" :title="comName" direction="ltr" size="30%">
           <div style="margin: 0 20px;">
                <el-table :data="kfData">
                    <!--  @click="copyText(scope.row.cetNumber,'证件编号')" -->
                    <el-table-column label="昵称">
                         <template #default="scope">
                            <span @click="copyText(scope.row.name,'客服昵称')">{{scope.row.name}}</span>
                         </template>
                    </el-table-column>
                     <el-table-column label="微信号">
                         <template #default="scope">
                            <span @click="copyText(scope.row.weChat,'客服微信号')">{{scope.row.weChat}}</span>
                         </template>
                    </el-table-column>
                </el-table>
           </div>
        </el-drawer>
        <!-- 业务员 -->
        <el-dialog v-model="comMemberShow"  align-center title="业务员" width="30%"> 
            <el-avatar style="margin: 0 10px;" :size="60" :src="circleUrl" />
            <br>
            <el-button style="margin: 20px 0;" type="primary" size="small" @click="copyText(memberName,'业务员姓名')">{{ memberName }}</el-button>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../components/PageinationView.vue';
import Host from "../store/host";
import Method from "../store/publicMethod";
export default {
    components: {
        Pagination
    },
    data(){
        return {
            tableData: [],
            label: "2",
            tableLoading: false,
            pageSize: 1,
            pageCount: 1,
            total: 0,
            kfShow: false,
            comName: '',
            input: "",
            kfData: [],
            grade: localStorage.getItem('grade'),
            comMemberShow: false,
            circleUrl: '',
            memberName: '',
        }
    },
     created(){
        this.changePage(1);
    },
    methods: {
      // 分页
      changePage(num){
        this.pageCount = num;
        this.getList();
      },
      // 获取列表
      async getList(){
        this.tableLoading = true;
        if(this.label != 0 && this.label != 1 && this.label != 2) return this.$message({message: '请求失败',type: 'danger'});
        let postUrl = '/api/getMcList?state='+ this.label +'&page=' + this.pageCount + '&rid=';
        if(this.grade == 3){
            postUrl = postUrl + localStorage.getItem('id');
        } else { postUrl = postUrl + "false"}
        let resolveData = await Host.HostGet(postUrl);
        this.tableData = await Host.setList(resolveData.data);
        this.total = resolveData.total
        this.tableLoading = false;

      },
      btnPane(e){
        this.label = e;
        this.changePage(1);
      },
      // 审核
      audit(id){
         this.$confirm('是否同意该企业完成认证', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '驳回',
                type: 'warning'
            }).then(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                let postUrl = '/api/setMcState';
                let data = {id: id,state: 2,type: 'audit'};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: '操作失败',type: 'danger'});
                this.getList();
                this.$message({message: '操作成功',type: 'success'});
            }).catch(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                let postUrl = '/api/setMcState';
                let data = {id: id,state: 1,type: 'audit'};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: '操作失败',type: 'danger'});
                this.getList();
                this.$message({message: '操作成功',type: 'success'});
            });
      },
      // 修改状态
      setMcState(id,state){
        this.$confirm('是否修改该企业状态', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                let postUrl = '/api/setMcState';
                let data = {id: id,state: state,type: 'state'};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: '操作失败',type: 'danger'});
                this.getList();
                this.$message({message: '操作成功',type: 'success'});
            }).catch(async () => {});
      },
      // 查看营业执照
      lookComImg(imgName){
        window.open('https://wanxiang.slenb.cn/ComImg/' + imgName +'.png', '_blank');
      },
      // 查看推广码
      lookShareImg(imgName){
         window.open('https://wanxiang.slenb.cn/WeChatQRCode/' + imgName +'.jpg', '_blank');
      },
      // 复制文字
      copyText(text,msg){
        Method.copyText(text,msg);
        this.$message({message: '已复制' + msg,type: 'success'});
      },
      // 获取企业客服
      async getSerivceList(id,comName){
        let tc = this.$message({message: '加载中',type: 'info',duration: 0});
        let postUrl = '/api/getSerivceList?id='+ id;
        let resolveData = await Host.HostGet(postUrl);
        tc.close();
        if(resolveData.code !== 200) return this.$message({message: '失败',type: 'danger'});
        this.kfData = resolveData.data;
        this.comName = comName;
        this.kfShow = true;
      },
      // 搜索
      async searchBtn(){
        if(this.input.length == 0) return this.$message({message: '内容不能为空',type: 'danger'});
        this.tableLoading = true;
        if(this.label != 0 && this.label != 2) return this.$message({message: '请求失败',type: 'danger'});
        this.pageCount = 1;
        let postUrl = `/api/search/mc?value=${this.input}&page=${this.pageCount}&rid=`;
        if(this.grade == 3){
            postUrl = postUrl + localStorage.getItem('id');
        } else { postUrl = postUrl + "false"}
        let resolveData = await Host.HostGet(postUrl);
        this.tableLoading = false;
        if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
        this.tableData = await Host.setList(resolveData.data);
        this.total = resolveData.total
      },
      // 分配
      allot(id,comName){
        this.$prompt(comName, '分配业务员', {
            confirmButtonText: '确认转移',
            cancelButtonText: '取消',
            inputPlaceholder: '交接的业务员ID',
            inputPattern: /^\d+$/,
            inputErrorMessage: '请输入的ID有误'
            }).then(async({ value }) => {
                if(id == value)  this.$message({message: '不能转移本身',type: 'info'});
                // 转移
                let postUrl = `/javaApi/member/allot`;
                let obj = {id: id,leaveId: value}
                let tc = this.$message({message: '加载中',type: 'info',duration: 0});
                let resolveData = await Host.HostPost(postUrl,obj);
                tc.close()
                if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
                this.$message({message: "已转移经营体: "  + resolveData.count + "个",type: 'success'});
                this.getList();
            }).catch(() => {      
        });
      },
      // 获取业务员
      async getComMember(id){
        let tc = this.$message({message: '加载中',type: 'warning',duration: 0});
        let postUrl = `/javaApi/member/get?id=` + id;
        let result = await Host.HostGet(postUrl);
        tc.close()
        if(result.code != 200) return this.$message({message: result.msg,type: 'warning'});
        this.circleUrl = result.data.headImg;
        this.memberName = result.data.beName;
        this.comMemberShow = true;
      }
    }
} 
</script>

<style>
    .searchBox {
        display: flex;
    }
</style>
