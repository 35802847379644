<!-- 
        数据概述
-->
<template>
    <div class="gaine"  v-loading="tableLoading" style="background-color: #ffffff86;">
         <!-- 数据统计-->
         <div class="container">
            <el-row :gutter="10">
                <el-col :span="6">
                    <div class="statistics" style="background:#5474db" v-if="grade != 3">
                        <div class="title"><p>总用户</p></div>
                        <div class="num">{{ dataList.all.user }}</div>
                        <div class="tip">当前总用户数量</div>
                    </div>
                </el-col>
                <!-- <el-col :span="6">
                    <div class="statistics" style="background:#1890ff">
                        <div class="title"><p>总经营体</p></div>
                        <div class="num">{{ dataList.all.mc }}</div>
                        <div class="tip">当前总经营体数量</div>
                    </div>
                </el-col> -->
                <el-col :span="6">
                    <div class="statistics" style="background:#3eaf7c" >
                        <div class="title"><p>总订单</p></div>
                        <div class="num">{{ dataList.all.order }}</div>
                        <div class="tip">当前总回收订单数量</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#13c2c2" >
                        <div class="title"><p>总交易额</p></div>
                        <div class="num">{{ dataList.all.money }}</div>
                        <div class="tip">当前总回收订单交易额</div>
                    </div>
                </el-col>
            </el-row>
        </div>
         <el-row>
            <el-col :span="12" style="margin: 10px 0;">
                <div class="littleBox">
                    <div style="display: flex; margin: 10px;"><el-tag size="mini" type="danger">今日实时数据</el-tag></div>
                    <div class="showBox">
                        <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                            <el-statistic title="新增用户" :value="dataList.today.user"/>
                            </el-col>
                            <!-- <el-col :span="6" style="margin: 10px 0;">
                            <el-statistic title="新增商家" :value="dataList.today.mc"/>
                            </el-col> -->
                        </el-row>
                        <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收订单量" :value="dataList.today.order"/>
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收机器数量" :value="dataList.today.orderCount"/>
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交金额" :value="dataList.today.money"/>
                            </el-col>
                            <!-- <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交利润" :value="'开发中'"/>
                            </el-col> -->
                        </el-row>
                        <!-- <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收订单量" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收机器数量" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交金额" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交利润" :value="'开发中'"/>  
                            </el-col>
                        </el-row> -->
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="margin: 10px 0;">
                <div class="littleBox">
                    <div style="display: flex; margin: 10px;"><el-tag size="mini" type="warning">昨日实时数据</el-tag></div>
                    <div class="showBox">
                        <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                            <el-statistic title="新增用户" :value="dataList.yesterday.user"/>
                            </el-col>
                            <!-- <el-col :span="6" style="margin: 10px 0;">
                            <el-statistic title="新增商家" :value="dataList.yesterday.mc"/>
                            </el-col> -->
                        </el-row>
                        <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收订单量" :value="dataList.yesterday.order"/>
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收机器数量" :value="dataList.yesterday.orderCount"/>
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交金额" :value="dataList.yesterday.money"/>
                            </el-col>
                            <!-- <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交利润" :value="'开发中'"/>
                            </el-col> -->
                        </el-row>
                        <!-- <el-row>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收订单量" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收机器数量" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交金额" :value="'开发中'"/>  
                            </el-col>
                            <el-col :span="6" style="margin: 10px 0;">
                                <el-statistic title="回收成交利润" :value="'开发中'"/>  
                            </el-col>
                        </el-row> -->
                    </div>
                </div>
            </el-col>
        </el-row>
         
         <!-- <div class="container">
            <el-row :gutter="10">
                <el-col :span="6">
                    <div class="statistics" style="background:#5474db" v-if="grade != 3">
                        <div class="title"><p>总零售成交</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">当前零售总成交金额</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#1890ff">
                        <div class="title"><p>总回采成交</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">当前回采总成交金额</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#3eaf7c" >
                        <div class="title"><p>总零售利润</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">当前零售总成交利润</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#13c2c2" >
                        <div class="title"><p>总回采利润</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">当前回采总成交利润</div>
                    </div>
                </el-col>

                <el-col :span="6">
                    <div class="statistics" style="background:#5474db" >
                        <div class="title"><p>已售机器统计</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">当前总零售机器数量</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#1890ff" >
                        <div class="title"><p>回采机器统计</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">总零售回采机器数量</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#3eaf7c" >
                        <div class="title"><p>回采机器统计</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">总零售回采机器数量</div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="statistics" style="background:#13c2c2" >
                        <div class="title"><p>机器库存统计</p></div>
                        <div class="num">{{ '开发中' }}</div>
                        <div class="tip">总零售回采机器数量</div>
                    </div>
                </el-col>
            </el-row>
        </div> -->

        <!-- end -->
        <!-- <div>
            <div class="container">
                <div class="boxs"><el-statistic :value="dataList.today.money" title="今日成交金额" :value-style="{color: '#F56C6C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.yesterday.money" title="昨日成交金额" :value-style="{color: '#F56C6C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.all.money" title="总成交金额" :value-style="{color: '#F56C6C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="boxs" v-if="grade != 3"><el-statistic :value="dataList.today.user" title="今日新增用户" :value-style="{color: '#409EFF','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.today.mc" title="今日新增商家" :value-style="{color: '#409EFF','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.today.order" title="今日新增订单" :value-style="{color: '#409EFF','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.today.count" title="今日下单产品数量" :value-style="{color: '#409EFF','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="boxs" v-if="grade != 3"><el-statistic :value="dataList.yesterday.user" title="昨日新增用户" :value-style="{color: '#E6A23C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.yesterday.mc" title="昨日新增商家" :value-style="{color: '#E6A23C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.yesterday.order" title="昨日新增订单" :value-style="{color: '#E6A23C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.yesterday.count" title="昨日下单产品数量" :value-style="{color: '#E6A23C','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
        </div>
        <div>
            <div class="container">
                <div class="boxs" v-if="grade != 3"><el-statistic :value="dataList.all.user" title="全部用户" :value-style="{color: '#67C23A','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.all.mc" title="全部商家" :value-style="{color: '#67C23A','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.all.order" title="全部订单" :value-style="{color: '#67C23A','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
                <div class="boxs"><el-statistic :value="dataList.all.count" title="全部下单产品数量" :value-style="{color: '#67C23A','font-size': '30px', 'text-align': 'center'}"></el-statistic></div>
            </div>
        </div> -->
    </div>

</template>

<script>
import Host from "../store/host";
export default {
  data() {
    return {
        tableLoading: false,
        dataList: {
            today: {
                user: 0,
                mc: 0,
                order: 0,
                count: 0,
                money:0,
            },
            yesterday: {
                user: 0,
                mc: 0,
                order: 0,
                count: 0,
                money: 0,
            },
            all: {
                user: 0,
                mc: 0,
                order: 0,
                count: 0,
                money: 0,
            }
        },
        grade: localStorage.getItem('grade'),
    }
  },
  mounted() {
    this.getDataShow();
  },
  methods: {
    async getDataShow(){
        this.tableLoading = true;
        let postUrl = `/javaApi/data/select`;
        let resolveData = await Host.HostGet(postUrl);
        this.tableLoading = false;
        if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'warning'});
        if(!resolveData.data) return;
        this.dataList = resolveData.data;
    }
  }
};
</script>


<style>
    .gaine {
        width: 100vw;
        height: 100vh;
        background: #f6f6f6;
        /* background: #fff; */
    }
    .container {
        overflow: hidden;
        /* background: #fff; */
        margin: 0px 10px 0;
        padding: 5px 0;
        /* border-radius: 10px; */
    }

    .boxs {
        float: left;
        width: 19vw;
        /* background: #fff; */
        background: #f6f6f6dd;
        
        margin: 10px;
        padding: 20px 0;
        border-radius: 10px;
    }

    /* 数据统计 */
    .el-row{
        padding: 0 9px;
   }
   .statistics{
        color: white;
        height: 100px;
        border-radius:4px;
        padding: 0 8px;
        margin: 10px 0;
    }
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
    }
    .statistics p {
        font-size: 13px;
        font-weight: bold;
    }
    .statistics .el-statistic__number,
    .num {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 10px 0;
        color: #fff;
    }
    .tip{
        font-size: 12px;
    }

    /* 小盒子 */
    .littleBox {
        display: block;
        background: #fff;
        margin: 10px 0;
        margin-left: 10px;
        padding-bottom: 20px;
        border-radius: 10px;
        padding-top: 5px;
    }
    .littleBox .statistics{
        color: white;
        border-radius:4px;
        padding: 0 8px;
    }
    .littleBox .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /* 小盒子里的li */
    .littleBox .li {
        margin-bottom: 10px;
        padding: 11px 0;
        border-radius: 5px;
    }

    .littleBox .li .tip{
        font-size: 12px;
        color: #fff;
    }
</style>