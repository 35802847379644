<!--

        公司部门

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;"  @tab-change="btnPane" v-model="label">
            <el-tab-pane label="部门管理" name="departmemt" v-if="grade == 1"></el-tab-pane>
            <el-tab-pane label="成员管理" name="member"></el-tab-pane>
            <!-- 部门管理 -->
            <div class="departmemt" v-show="label == 'departmemt'">
               <div style="display: flex;">
              
                    <el-input size="small" style="width: 120px;" placeholder="输入部门昵称" v-model="name" clearable></el-input>
                    <el-input size="small" style="width: 120px;margin-left: 10px;" placeholder="输入管理人姓名" v-model="beName" clearable></el-input>
                    <el-button style="margin-left: 10px;" size="small" type="primary" @click="addDepartment">添加部门</el-button>
               </div>
               <div style="height: 20px;"></div>
               <div>
                    <el-table style="display: flex;" :data="deparmentData" height="600" v-loading="tableLoading">
                        <el-table-column label="部门昵称" width="120">
                            <template #default="scope">
                                <el-input placeholder="输入部门昵称" size="small" v-model="scope.row.name" clearable></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="部门负责人">
                            <template #default="scope">
                                <!--  -->
                                <el-input style="width: 100px;" placeholder="输入部门管理人" size="small" v-model="scope.row.beName" clearable></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="200">
                            <template #default="scope">
                                <el-button link type="primary" size="small" @click="updateDepartment(scope.row.id,scope.row.name,scope.row.beName)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
               </div>
            </div>
            <!-- 成员管理 -->
            <div class="member" v-show="label == 'member'">
                <div style="display: flex; justify-content: space-between;">
                    <!-- left -->
                   <div style="align-self: flex-start;">
       
                    <el-select  v-if="grade == 1" style="width: 100px;" v-model="value" size="small" class="m-2" placeholder="请选择部门" @change="handRadio">
                        <el-option label="超级管理员" value="1"/>
                        <el-option
                            v-for="item in deparmentData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id.toString()"
                        />
                    </el-select>
             
        
                    <el-select 
                    size="small"
                    v-show="value != '1'" 
                    style="width: 100px; margin-left: 10px;"  @change="handRadios" v-model="identity" class="m-2" placeholder="可选身份" :disabled="grade == 2">
                        <el-option label="管理" value="2" />
                        <el-option label="成员" value="3" />
                    </el-select>

                    <el-input style="width: 100px;margin-left: 10px;" placeholder="输入姓名" size="small" v-model="beName" clearable></el-input>
                    <el-input style="width: 130px;margin-left: 10px;" placeholder="输入手机号" size="small" v-model="phone" clearable></el-input>
                    <el-button style="margin-left: 10px;" size="small" type="primary" @click="addMember">添加成员</el-button>
                   </div>
                   <!-- right -->
                    <div style="align-self: flex-end;">
                        <el-input style="width: 300px;" v-model="input" placeholder="请输入姓名" clearable/>
                        <el-button style="margin-left: 10px;" type="primary" @click="searchBtn" circle><el-icon><Search /></el-icon></el-button>
                    </div>
                </div>
                <div style="height: 20px;"></div>
                <div>
                    <el-table style="display: flex;" height="600" :data="MemberData" v-loading="tableLoading">

                        <el-table-column label="管理员ID" width="90">
                            <template #default="scope">
                                <el-tag @click="copyText(scope.row.id,'管理员ID')">Copy</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="头像" width="80" align="center">
                            <template #default="scope">
                                <el-avatar shape="square" :size="50" :src="scope.row.headImg" />
                            </template>
                        </el-table-column>

                        <el-table-column label="权限身份" width="100" align="center">
                            <template #default="scope">
                                <el-text v-if="scope.row.grade == 1">超级管理</el-text>
                                <el-text v-else-if="scope.row.grade == 2">管理</el-text>
                                <el-text v-else>成员</el-text>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属部门" width="100" align="center">
                            <template #default="scope">
                                <el-text v-if="scope.row.grade == 1">无</el-text>
                                <div v-else v-for="item in deparmentData" :key="item.id">
                                    <el-text v-if="item.id == scope.row.cid"> {{ item.name }} </el-text>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="姓名" width="100" align="center">
                            <template #default="scope">
                                <el-text> {{ scope.row.beName }} </el-text>
                            </template>
                        </el-table-column>

                        <el-table-column label="手机号" width="120">
                            <template #default="scope">
                                <el-text> {{ scope.row.phone }} </el-text>
                            </template>
                        </el-table-column>

                       

                        <el-table-column label="添加时间">
                            <template #default="scope">
                                <el-text> {{ scope.row.regTime }} </el-text>
                            </template>
                        </el-table-column>


                        <el-table-column fixed="right" label="操作" width="300">
                            <template #default="scope">
                                <template v-for="(item,index) in deparmentData" :key="item.id">
                                    <template v-if="identity == 3 && item.id == scope.row.cid && item.name.includes('运营')">
                                        <!-- 686654597 -->
                                        <el-button link type="primary" size="small" @click="copyText('https://recycle.slenb.cn/#/?token=' + scope.row.timer,'链接')">回采工单</el-button>
                                        <el-button link type="primary" size="small" @click="downloadShareMcCode(scope.row.id,scope.row.token,scope.row.beName)">下载推广码</el-button>
                                        <el-button link type="danger" size="small" @click="btnDimission(scope.row.id)">离职转移</el-button>
                                    </template>
                                    <el-button v-else-if="deparmentData.length - 1 == index" link type="danger" size="small" @click="delMember(scope.row.id,scope.row.grade)">移除</el-button>
                                </template>
                               
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-tabs>
        
        <!-- 添加站点管理员弹窗 -->
        <el-dialog v-model="adddialog" align-center title="授权登录" width="30%" @close="adddialogClose"> 
                <el-text v-if="value == 1">超级管理员</el-text>

                <el-text v-else  v-for="item in deparmentData" :key="item.id">
                    <el-text v-if="item.id == value"> {{ item.name }}</el-text>
                </el-text>

                <el-text v-if="identity == 2 && value != 1">部门管理员</el-text>
                <el-text v-else-if="identity == 3 && value != 1">成员</el-text>
                <br/>
                <el-text>姓名: {{ beName }} 手机号: {{ phone }}</el-text>
            <div class="loginImgBox" v-show="qrShow">
                <div v-show="loadShow"><el-image style="width: 300px; height: 300px;border-radius: 10px;" :src="qr_code" /></div>
                <div v-show="!loadShow"><el-image style="width: 50px; height: 50px" :src="loadImg" /></div>
            </div>
            <div v-show="qrShow">请在 {{ count }} 秒内完成扫码</div>
        </el-dialog>
    </div>
</template>

<script>
import Host from "../store/host";
import qrcode from 'qrcode';
import axios from 'axios';
import Method from "../store/publicMethod";
export default {
    data(){
        return {
            label: "departmemt",
            value: "1",
            identity: '2',
            name: '',
            beName: '',
            phone: '',
            qr_code: '',
            input: '',
            McShateQRCodedialog:false,
            qrShow: false,
            adddialog: false,
            count: 0,
            uid: this.getRandomInt(1, 10000),
            deparmentData: [],
            MemberData: [],
            grade: localStorage.getItem('grade'),
            dimission: true,
           
        }
    },
    created(){
        if(this.grade == 2){
            this.depatmemtPlus();
            this.getList();
        }
        this.getDepartmemt();
    },
    methods: {
        // 顶部分类
        btnPane(e){
            this.label = e;
            this.beName = "";
            this.phone = '';
            if(e == "departmemt"){
                this.getDepartmemt()
                return;
            }
            if(e == "member"){
                this.getList();
                return;
            }
            
        },
         // 添加成员窗口关闭
         adddialogClose(){
            clearTimeout(this.timer);
            this.adddialog = false;
            this.qrShow = false;
        },
        // 选择器
         handRadio(val){
            // if(val == 1){
            //     this.identity = '2';
            // }
            this.value = val.toString();
            this.getList();
        },
        // 选择器
        handRadios(val){
            this.identity = val.toString();
            this.getList();
        },
        // 获取部门列表
        async getDepartmemt(){
            this.tableLoading = true;
            let postUrl = `/javaApi/department/list`;
            let resolveData = await Host.HostGet(postUrl);
            this.deparmentData = resolveData;
            this.tableLoading = false;
        },
        // 部门主管
        async depatmemtPlus(){
            this.label = "member";
            this.value = localStorage.getItem('cid');
            this.identity = "3"
        },
        copyText(text,msg){
            Method.copyText(text,msg);
            this.$message({message: '已复制' + msg,type: 'success'});
        },
        // 添加部门
        async addDepartment(){
            if(this.name.length == 0) return this.$message({message: "请输入部门名称",type: 'warning'});
            if(this.beName.length == 0) return this.$message({message: "请输入部门管理人姓名",type: 'warning'});
            let postUrl = `/javaApi/department/add`;
            let obj = {
                name: this.name,
                beName: this.beName
            }
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});
            let resolveData = await Host.HostPost(postUrl,obj);
            console.log(resolveData)
            if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
            tc.close()
            this.$message({message: resolveData.msg,type: 'success'});
            this.name = '';
            this.beName = '';
            this.getDepartmemt();
        },
        // 修改部门
        async updateDepartment(id,name,beName){
            let postUrl = `/javaApi/department/update`;
            let obj = {
                id: id,
                name: name,
                beName: beName
            }
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});
            let resolveData = await Host.HostPost(postUrl,obj);
            console.log(resolveData)
            if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
            tc.close()
            this.$message({message: resolveData.msg,type: 'success'});
            this.beName = '';
            this.getDepartmemt();
        },
        // 获取成员列表
        async getList(){
            this.tableLoading = true;
            var str = '';
            if(this.value == "1"){
                str = `&grade=${this.value}`;
            } else {
                str = `&grade=${this.identity}&cid=${this.value}`;
            }
            let postUrl = `/api/getAdminList?${str}`;
            let resolveData = await Host.HostGet(postUrl);
            this.MemberData = await Host.setList(resolveData.data);
            this.tableLoading = false;
        },
        // 添加成员
        addMember(){
            // 拦截条件
            if(this.beName.length <= 0) return this.$message({message: '请输入姓名',type: 'warning'});
            if(this.phone.length <= 0) return this.$message({message: '请输入手机号',type: 'warning'});
            const phoneNumberPattern = /^1[3456789]\d{9}$/;
            if(!phoneNumberPattern.test(this.phone)) return this.$message({message: '手机号有误',type: 'warning'});
            var str = '';
            if(this.value == "1"){ 
                str = `&grade=${this.value}&cid=null&beName=${this.beName}&phone=${this.phone}`;
            } else if(this.grade == 2){
                str = `&grade=${this.identity}&cid=${this.value}&beName=${this.beName}&phone=${this.phone}`;
            } else {
                str = `&grade=${this.identity}&cid=${this.value}&beName=${this.beName}&phone=${this.phone}`;
            }
            this.getLoadShow(str);
        },
        // 移除成员
        async delMember(id,grade){
            if(!(localStorage.getItem("grade") < grade)){
                this.$message({message: "无权限",type: 'warning'});
                return;
            }
            let postUrl = `/javaApi/member/del`;
            let obj = {id: id,grade: grade}
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});
            let resolveData = await Host.HostPost(postUrl,obj);
            tc.close();
            if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'info'});
            this.$message({message: resolveData.msg,type: 'success'});
            this.getList();
        },
        // 授权二维码
        async getLoadShow(str){
            let postUrl = `/api/wechat/qrCode?type=auth&uid=${this.uid}` + str;
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
            await this.createQrCode(resolveData.url);
            tc.close()
            this.adddialog = true;
            this.qrShow = true;
            this.count = 60;
            this.countDown(); // 倒计时
        },
        // qrCode生成二维码
        async createQrCode(url){
            qrcode.toDataURL(url, { width: 200 }).then(url => {
                this.qr_code = url;
                this.loadShow = true;
                return true;
            }).catch(err => {return false;});
        },
        // 倒计时
        countDown() {
            this.timer = setTimeout(() => {
                this.count--;
                if (this.count > 0) {
                    // 监听
                    this.getlogin();
                    this.countDown();
                } 
                else {
                    this.$message({message: '已超时!',type: 'warning'});
                    this.adddialog = false;
                    this.loadShow = false;
                    this.qrShow = false;
                }
            }, 1000); // 每1秒执行一次
        },
        // 监听登录
        async getlogin(){
            let postUrl = `/api/wechat/get?uid=${this.uid}`;
            let resolveData = await Host.HostGet(postUrl);
            console.log(resolveData);
            if(resolveData.code != 200) return;
            // 关闭倒计时
            clearTimeout(this.timer);
            this.$message({message: '授权成功',type: 'success'});
            this.qrShow = false;
            this.adddialog = false;
            this.getList();
        },
        // 搜索成员
        async searchBtn(){
            if(this.input.length == 0) return this.$message({message: "请输入",type: 'warning'});
            let postUrl = `/javaApi/member/search`;
            let obj = {
                searchContent: this.input,
                cid: localStorage.getItem("cid")
            }
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});
            let resolveData = await Host.HostPost(postUrl,obj);
            tc.close();
            this.MemberData = await Host.setList(resolveData.data);
        },
        // uid
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        // 获取推广码
        async downloadShareMcCode(id,token,beName){
            let postUrl = `/api/getMcQRCode?id=${id}&token=${token}`;
            let tc = this.$message({message: '加载中',type: 'info',duration: 0});

            axios({
                url: postUrl,
                method: 'GET',
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                     code:localStorage.getItem('token')
                },
                }).then(response => {
                    if(response.code == 201) return this.$message({message: response.msg, type: 'warning'});
                    this.saveFile(response, beName);
                    tc.close();
                }).catch(error => {this.$message({message: "图片保存失败", type: 'warning'})});
        },
        // 保存
        saveFile(data, beName) {
            const blob = new Blob([data.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = beName + '.jpg';
            link.click();
            window.URL.revokeObjectURL(url);

            
        },
        // 离职转移
        async btnDimission(id){
            let tc = this.$message({message: '加载中',type: 'warning',duration: 0});
            let postUrl = `/javaApi/member/mcCount?id=` + id;
            let result = await Host.HostGet(postUrl);
            if(result.code != 200) return this.$message({message: '错误',type: 'warning'});
            tc.close()
            this.$prompt('当前业务员有'+ result.count +'个经营体', '离职转移', {
                confirmButtonText: '确认转移',
                cancelButtonText: '取消',
                inputPlaceholder: '交接的业务员ID',
                inputPattern: /^\d+$/,
                inputErrorMessage: '请输入的ID有误'
                }).then(async({ value }) => {
                    if(id == value)  this.$message({message: '不能转移本身',type: 'warning'});
                    // 转移
                    let postUrl = `/javaApi/member/leave`;
                    let obj = {id: id,leaveId: value}
                    tc = this.$message({message: '加载中',type: 'info',duration: 0});
                    let resolveData = await Host.HostPost(postUrl,obj);
                    if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
                    tc.close()
                    this.$message({message: "已转移经营体: "  + resolveData.count + "个",type: 'success'});
                    this.getList();
                }).catch(() => {      
            });
        }
    },
} 
</script>

<style>
    .spanT {
        /* margin: 30px 0; */
        color: #000;
    }
</style>